import { defineMessages } from 'react-intl';

export const sectionMessages = defineMessages({
    'sponsors.label': {
        id: 'v4_website.sponsors.label',
        defaultMessage: '###'
        // defaultMessage: 'Sponsors'
    },
    'sponsors.sponsors_list': {
        id: 'v4_website.sponsors.sponsors_list',
        defaultMessage: '###'
        // defaultMessage: 'Sponsor List'
    },
    'sponsors.sponsors_rotation': {
        id: 'v4_website.sponsors.sponsors_rotation',
        defaultMessage: '###'
        // defaultMessage: 'Dynamic sponsor rotations'
    },
    'sponsors.empty': {
        id: 'v4_website.sponsors.empty',
        defaultMessage: '###'
        // defaultMessage: 'No sponsors to show'
    },
    'sponsors.description.empty': {
        id: 'v4_website.sponsors.description.empty',
        defaultMessage: '###'
        // defaultMessage: 'No description to show'
    },
    'news.label': {
        id: 'v4_website.news.label',
        defaultMessage: '###'
        // defaultMessage: 'News'
    },
    'news.label_singular': {
        id: 'v4_website.news.label_singular',
        defaultMessage: '###'
        // defaultMessage: 'News'
    },
    'news.empty_news': {
        id: 'v4_website.news.empty_news',
        defaultMessage: '###'
        // defaultMessage: 'No news to show'
    },
    'website.view_more': {
        id: 'v4_website.website.view_more',
        defaultMessage: '###'
        // defaultMessage: 'View more'
    },
    'galleries.label': {
        id: 'v4_website.galleries.label',
        defaultMessage: '###'
        // defaultMessage: 'Galleries'
    },
    'galleries.no_photos': {
        id: 'v4_website.galleries.no_photos',
        defaultMessage: '###'
        // defaultMessage: 'No photos to show'
    },
    'galleries.no_galleries': {
        id: 'v4_website.galleries.no_galleries',
        defaultMessage: '###'
        // defaultMessage: 'No galleries to show'
    },
    'videos.label': {
        id: 'v4_website.videos.label',
        defaultMessage: '###'
        // defaultMessage: 'Videos'
    },
    'videos.no_videos': {
        id: 'v4_website.videos.no_videos',
        defaultMessage: '###'
        // defaultMessage: 'No Videos to show'
    },
    'calendar.no_events': {
        id: 'v4_website.calendar.no_events',
        defaultMessage: '###'
        // defaultMessage: 'No events to show'
    },
    'calendar.more_information': {
        id: 'v4_website.calendar.more_information',
        defaultMessage: '###'
        // defaultMessage: 'More information'
    },
    'calendar.view_event': {
        id: 'v4_website.calendar.view_event',
        defaultMessage: '###'
        // defaultMessage: 'View event'
    },
    'table.empty': {
        id: 'v4_website.table.empty',
        defaultMessage: '###'
        // defaultMessage: 'No results to show'
    },
    'table.header.player': {
        id: 'v4_website.table.header.player',
        defaultMessage: '###'
        // defaultMessage: 'Player'
    },
    'table.header.player_f': {
        id: 'v4_website.table.header.player_F',
        defaultMessage: '###'
        // defaultMessage: 'Player'
    },
    'table.header.club': {
        id: 'v4_website.table.header.club',
        defaultMessage: '###'
        // defaultMessage: 'Club'
    },
    'standings.standings_for': {
        id: 'v4_website.standings.standings_for',
        defaultMessage: '###'
        // defaultMessage: 'Standings Preview for'
    },
    'standings.comment': {
        id: 'v4_website.standings.comment',
        defaultMessage: '###'
        // defaultMessage: 'Comment'
    },
    'standings.point': {
        id: 'v4_website.standings.point',
        defaultMessage: '###'
        // defaultMessage: 'Point'
    },
    'standings.points': {
        id: 'v4_website.standings.points',
        defaultMessage: '###'
        // defaultMessage: 'Points'
    },
    'forms.form_expired': {
        id: 'v4_website.forms.form_expired',
        defaultMessage: '###'
        // defaultMessage: 'The form has expired and can no longer be filled out.'
    },
    'products.no_products': {
        id: 'v4_website.products.no_products',
        defaultMessage: '###'
        // defaultMessage: 'No products to show'
    },
    'games.empty': {
        id: 'v4_website.games.empty',
        defaultMessage: '###'
        // defaultMessage: 'No games to show'
    },
    'games.games_for': {
        id: 'v4_website.games.games_for',
        defaultMessage: '###'
        // defaultMessage: 'Games for'
    },
    'games.friendly_game': {
        id: 'v4_website.games.friendly_game',
        defaultMessage: '###'
        // defaultMessage: 'Friendly game'
    },
    'games.kick_off': {
        id: 'v4_website.games.kick_off',
        defaultMessage: '###'
        // defaultMessage: 'Kick off'
    },
    'games.cancelled': {
        id: 'v4_website.games.cancelled',
        defaultMessage: '###'
        // defaultMessage: 'Cancelled'
    },
    'games.previous_week': {
        id: 'v4_website.games.previous_week',
        defaultMessage: '###'
        // defaultMessage: 'Previous week'
    },
    'games.next_week': {
        id: 'v4_website.games.next_week',
        defaultMessage: '###'
        // defaultMessage: 'Next week'
    },
    'social.social_networks': {
        id: 'v4_website.social.social_networks',
        defaultMessage: '###'
        // defaultMessage: 'Social Networks'
    },
    'document.download': {
        id: 'v4_website.document.download',
        defaultMessage: '###'
        // defaultMessage: 'Download'
    },
    'document.published_on': {
        id: 'v4_website.document.published_on',
        defaultMessage: '###'
        // defaultMessage: 'Published on'
    },
    'navigation.tabs.statistics': {
        id: 'v4_website.navigation.tabs.statistics',
        defaultMessage: '###'
        // defaultMessage: 'Statistics'
    },
    'navigation.tabs.standings': {
        id: 'v4_website.navigation.tabs.standings',
        defaultMessage: '###'
        // defaultMessage: 'Standings'
    },
    'navigation.tabs.results': {
        id: 'v4_website.navigation.tabs.results',
        defaultMessage: '###'
        // defaultMessage: 'Results'
    },
    'navigation.tabs.roster': {
        id: 'v4_website.navigation.tabs.roster',
        defaultMessage: '###'
        // defaultMessage: 'Roster'
    },
    'section.label.most_recent':{
        id: 'v4_website.section.label.most_recent',
        defaultMessage: '###'
        // defaultMessage: 'Most recent'
    },
    'section.label.gameday':{
        id: 'v4_website.section.label.gameday',
        defaultMessage: '###'
        // defaultMessage: 'Gameday'
    },
    'section.label.league':{
        id: 'v4_website.section.label.league',
        defaultMessage: '###'
        // defaultMessage: 'League'
    },
    'section.label.stage':{
        id: 'v4_website.section.label.stage',
        defaultMessage: '###'
        // defaultMessage: 'Stage'
    },
    'section.label.season':{
        id: 'v4_website.section.label.season',
        defaultMessage: '###'
        // defaultMessage: 'Season'
    },
    'section.label.all':{
        id: 'v4_website.section.label.all',
        defaultMessage: '###'
        // defaultMessage: 'All'
    },
    'section.roster.label.players':{
        id: 'v4_website.section.roster.label.players',
        defaultMessage: '###'
        // defaultMessage: 'Players'
    },
    'section.roster.label.players_f':{
        id: 'v4_website.section.roster.label.players_F',
        defaultMessage: '###'
        // defaultMessage: 'Players'
    },
    'section.roster.label.staff':{
        id: 'v4_website.section.roster.label.staff',
        defaultMessage: '###'
        // defaultMessage: 'Staff'
    },
    'section.roster.label.members':{
        id: 'v4_website.section.roster.label.members',
        defaultMessage: '###'
        // defaultMessage: 'Members'
    },
    'section.roster.empty':{
        id: 'v4_website.section.roster.empty',
        defaultMessage: '###'
        // defaultMessage: 'No roster to show'
    },
    'section.player.profile.most_recent_stats':{
        id: 'v4_website.section.player.profile.most_recent_stats',
        defaultMessage: '###'
        // defaultMessage: 'Most recent stats'
    },
    'section.player.profile.career_statistics':{
        id: 'v4_website.section.player.profile.career_statistics',
        defaultMessage: '###'
        // defaultMessage: 'Career statistics'
    },
    'section.player.profile.date_of_birth':{
        id: 'v4_website.section.player.profile.date_of_birth',
        defaultMessage: '###'
        // defaultMessage: 'Date of birth'
    },
    'section.player.profile.height':{
        id: 'v4_website.section.player.profile.height',
        defaultMessage: '###'
        // defaultMessage: 'Height'
    },
    'section.player.profile.weight':{
        id: 'v4_website.section.player.profile.weight',
        defaultMessage: '###'
        // defaultMessage: 'Weight'
    },
    'section.player.profile.nationality':{
        id: 'v4_website.section.player.profile.nationality',
        defaultMessage: '###'
        // defaultMessage: 'Nationality'
    },
    'section.results.all_leagues':{
        id: 'v4_website.section.results.all_leagues',
        defaultMessage: '###'
        // defaultMessage: 'All leagues'
    },
    'section.calendar.event':{
        id: 'v4_website.section.calendar.event',
        defaultMessage: '###'
        // defaultMessage: 'Event'
    },
    'section.calendar.no_league':{
        id: 'v4_website.section.calendar.no_league',
        defaultMessage: '###'
        // defaultMessage: 'No League'
    },
    'section.calendar.calendar_for':{
        id: 'v4_website.section.calendar.calendar_for',
        defaultMessage: '###'
        // defaultMessage: 'Calendar for'
    },
    'section.calendar.events':{
        id: 'v4_website.section.calendar.events',
        defaultMessage: '###'
        // defaultMessage: 'Events'
    },
    'section.calendar.games':{
        id: 'v4_website.section.calendar.games',
        defaultMessage: '###'
        // defaultMessage: 'Games'
    },
    'section.calendar.trainings':{
        id: 'v4_website.section.calendar.trainings',
        defaultMessage: '###'
        // defaultMessage: 'Trainings'
    },
    'section.calendar.label':{
        id: 'v4_website.section.calendar.label',
        defaultMessage: '###'
        // defaultMessage: 'Calendar'
    },
    'section.calendar.all':{
        id: 'v4_website.section.calendar.all',
        defaultMessage: '###'
        // defaultMessage: 'All'
    },
    'section.videos.all_videos':{
        id: 'v4_website.section.videos.all_videos',
        defaultMessage: '###'
        // defaultMessage: 'All videos'
    },
    'section.videos.recent_videos':{
        id: 'v4_website.section.videos.recent_videos',
        defaultMessage: '###'
        // defaultMessage: 'Recent videos'
    },
    'section.video.created_by':{
        id: 'v4_website.section.video.created_by',
        defaultMessage: '###'
        // defaultMessage: 'Created by'
    },
    'section.news.all_news': {
        id: 'v4_website.section.news.all_news',
        defaultMessage: '###'
        // defaultMessage: 'All news'
    },
    'section.news.view_article': {
        id: 'v4_website.section.news.view_article',
        defaultMessage: '###'
        // defaultMessage: 'View article'
    },
    'section.news.photo_by': {
        id: 'v4_website.section.news.photo_by',
        defaultMessage: '###'
        // defaultMessage: 'Photo by'
    },
    'section.gallery.view_game_page': {
        id: 'v4_website.section.gallery.view_game_page',
        defaultMessage: '###'
        // defaultMessage: 'View game page'
    },
    'section.gallery.photographed_by': {
        id: 'v4_website.section.gallery.photographed_by',
        defaultMessage: '###'
        // defaultMessage: 'Photographed by'
    },
    'section.shop.view_item': {
        id: 'v4_website.section.shop.view_item',
        defaultMessage: '###'
        // defaultMessage: 'View item'
    },
    'section.event.will_you_participate': {
        id: 'v4_website.section.event.will_you_participate',
        defaultMessage: '###'
        // defaultMessage: 'Will you participate?'
    },
    'section.games.friendly_games': {
        id: 'v4_website.section.games.friendly_games',
        defaultMessage: '###'
        // defaultMessage: 'Friendly games'
    },
    'navigation.btn.shop': {
        id: 'v4_website.navigation.btn.shop',
        defaultMessage: '###'
        // defaultMessage: 'Shop'
    },
    'navigation.btn.tickets': {
        id: 'v4_website.navigation.btn.tickets',
        defaultMessage: '###'
        // defaultMessage: 'Tickets'
    },
    'section.combination.age_category': {
        id: 'v4_website.section.combination.age_category',
        defaultMessage: '###',
        // defaultMessage: 'Age category'
    },
    'section.combination.show_standings': {
        id: 'v4_website.section.combination.show_standings',
        defaultMessage: '###',
        // defaultMessage: 'Show Standings'
    },
    'posts.posts': {
        id: 'v4_website.posts.posts',
        defaultMessage: '###'
        // defaultMessage: 'Posts'
    },
    'posts.empty_posts': {
        id: 'v4_website.posts.empty_posts',
        defaultMessage: '###'
        // defaultMessage: 'No posts to show'
    },
    'posts.all_posts': {
        id: 'v4_website.posts.all_posts',
        defaultMessage: '###'
        // defaultMessage: 'All posts'
    },
    'posts.view_event_page': {
        id: 'v4_website.posts.view_event_page',
        defaultMessage: '###'
        // defaultMessage: 'View event page'
    },
    'posts.fill_out_form': {
        id: 'v4_website.posts.fill_out_form',
        defaultMessage: '###'
        // defaultMessage: 'Fill out form'
    },
    'posts.view_profile': {
        id: 'v4_website.posts.view_profile',
        defaultMessage: '###'
        // defaultMessage: 'View profile'
    },
})
